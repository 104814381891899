import React from 'react'
import {Helmet} from "react-helmet";

function Photos() {
  return (
    <div className='container mx-auto'>
        <Helmet>
            <title>Photos - mutlukasap.dev</title>
        </Helmet>
      <div className='p-6 lg:p-10 border rounded-md bg-green-500/5 border-green-500/10'>
        <h2 className='text-xl lg:text-4xl font-bold lg:leading-[46px]'>Photos</h2>
        <p className='text-[15px] lg:text-lg text-gray-400 py-4'>I don't like taking photos ☹️ If I change my mind about this in the future, I will fill in here.</p>
      </div>
    </div>
  )
}

export default Photos